<!-- 作业栏 -->
<template>
  <div>
    <div class="title_css">{{moment(time).format('YYYY年MM月DD日')}} 作业</div>
    <div class="zoom-in_css">
      <el-button type="primary" icon="el-icon-zoom-in" @click="zoomClick('d')">字体放大</el-button>
      <el-button type="primary" icon="el-icon-zoom-out" @click="zoomClick('x')">字体缩小</el-button>
    </div>
    <div class="box_item_css px1610" :style="{'--zoomValue': zoomValue + 'px'}">
      <div v-for="(item, index) in list" :key="index" @click="noticeVisibleTrue(item)">
        <span>{{ item.hwTitle }}</span>
        <span v-html="item.hwContent"></span>
      </div>
      <!-- <i v-for="item in 3" :key="'id_' + item"></i> -->
    </div>
    <el-dialog
      :title="noticeObj.hwTitle + ' ' + moment(noticeObj.sendTime).format('MM-DD')"
      :visible.sync="dialogVisible"
      append-to-body
      :show-close="false"
      width="50%"
    >
      <div v-html="noticeObj.hwContent"></div>
      <div v-for="(item,index) in noticeObj.allFiles" :key="index">
        <div v-if="item.fileType == 'file'" class="box_file_css" @click="clickFile(item)">{{ item.attachName }}</div>
      </div>
      <div class="box_item_img_css">
        <div v-for="(item,index) in noticeObj.allFiles" :key="index" class="box_image" v-if="item.fileType != 'file'">
          <video
            :controls="false"
            @click="drawerObjClick(item.url)"
            v-if="item.fileType == 'video'"
            style="object-fit: cover;width: 180px;height: 180px;border-radius: 5px;"
            :src="item.url"
          ></video>
          <el-image v-if="item.fileType == 'image'" style="object-fit: cover;width: 180px;height: 180px;border-radius: 5px;" :src="item.url" lazy :preview-src-list="urls" fit="cover"></el-image>
        </div>
        <i v-for="item in 5" :key="'id_' + item"></i>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">关 闭</el-button>
      </span>
    </el-dialog>
    <el-drawer
      title=""
      :visible.sync="drawerB"
      append-to-body
      size="100%"
      direction="btt"
      :with-header="false"
    >
      <el-button class="top_drawer_cssB" type="danger" @click="drawerB = false">关闭视频</el-button>
      <div>
        <video
          autoplay
          controls
          class="video_cssB"
          v-if="drawerB"
          :src="drawerObj.imgurl"
        ></video>
      </div>
    </el-drawer>
    <el-dialog
      :title="files.attachName"
      :visible.sync="filesVisible"
      append-to-body
      :show-close="false"
      width="80%"
      top="5vh"
    >
      <div style="height: 75vh;">
        <iframe :src="files.url"  width="100%" height="100%" border="0"></iframe>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="filesVisible = false">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { getClassTask } from "@/api/index.js";
import moment from 'moment';
export default {
  data() {
    return {
      moment:moment,
      time: '',
      filesVisible:false,
      files:{
        attachName: '',
        url: '',
      },
      zoomValue:Number(localStorage.getItem('zoomValue')) || 32,
      classID: localStorage.getItem('classID'),
      dialogVisible: false,
      drawerB: false,
      drawerObj:{
        title:'',
        imgurl:''
      }, // 播放视频
      noticeObj:{
        hwTitle: '',
        hwContent: '',
        sendTime: '',
        allFiles:[]
      },
      urls:[],
      list: [
      //   {
      //   hwTitle:'语文作业',
      //   hwContent: '1.买一本楷书字帖，放假期间共写10面。2.买《课外文言文》《课外现代文》（济南出1.买一本楷书字帖，放假期间共写10面。2.买《课外文言文》《课外现代文》（济南出2.买《课外文言文》《课外现代文》（济南出1.买一本楷书字帖，放假期间共写10面。2.买《课外文言文》《课外现代文》（济南2.买《课外文言文》《课外现代文》（济南出1.买一本楷书字帖，放假期间共写10面。2.买《课外文言文》《课外现代文》（济南'
      // },{
      //   hwTitle:'10.18数学作业',
      //   hwContent: '1.买一本楷书字帖，放假期间共写10面。2.买《课外文言文》《课外现代文》（济南出1.买一本楷书字帖，放假期间共写10面。2.买《课外文言文》《课外现代文》（济南出'
      // },{
      //   hwTitle:'英语',
      //   hwContent: '1.买一本楷书字帖，放假期间共写10面。2.买《课外文言文》《课外现代文》（济南出1.买一本楷书字帖，放假期间共写10面。2.买《课外文言文》《课外现代文》（济南出'
      // },{
      //   hwTitle:'语文作业',
      //   hwContent: '1.买一本楷书字帖，放假期间共写10面。2.买《课外文言文》《课外现代文》（济南出1.买一本楷书字帖，放假期间共写10面。2.买《课外文言文》《课外现代文》（济南出2.买《课外文言文》《课外现代文》（济南出1.买一本楷书字帖，放假期间共写10面。2.买《课外文言文》《课外现代文》（济南2.买《课外文言文》《课外现代文》（济南出1.买一本楷书字帖，放假期间共写10面。2.买《课外文言文》《课外现代文》（济南'
      // },
      ],
    };
  },
  created(){
    this.time = localStorage.getItem('newTime') || moment().format('YYYY-MM-DD')
    getClassTask({
      ClassID: this.classID,
      startindex: 1,
			endindex: 200,
      startDate: moment().format('YYYY-MM-DD'),
      endDate: moment().add(1, 'days').format('YYYY-MM-DD'),
      hwType:1
    }).then((res) => {
      this.list = res.data
    });
  },
  methods:{
    // 打开详情
    noticeVisibleTrue(item){
      this.noticeObj = item
      this.dialogVisible = true
      this.urls = []
      this.noticeObj.allFiles.map(val =>{
        if(!['.mp4','.avi','.wmv','.mpg','.mpeg','.mov','.ram','.rm','.swf','.flv'].includes(val.Suffix)){
          this.urls.push(val.url)
        }
      })
    },
    drawerObjClick(url){
      this.drawerObj.imgurl = url
      this.drawerB = true
    },
    clickFile(e){
      this.files = e
      this.filesVisible = true
    },
    zoomClick(e){
      if(e == 'd'){
        if(this.zoomValue < 60){
          this.zoomValue += 2
          localStorage.setItem('zoomValue',this.zoomValue)
        }
      } else if(e == 'x'){
        if(this.zoomValue > 10){
          this.zoomValue -= 2
          localStorage.setItem('zoomValue',this.zoomValue)
        }
      }
    }
  }
};
</script>
<style scoped>
.box_item_css {
  /* display: flex;
  flex-wrap: wrap;
  justify-content: center; */
  height: calc(100vh - 230px);
  overflow-y: auto;
}
.zoom-in_css{
  position: fixed;
  top: 45px;
  right: 50px;
  z-index: 999;
}
.box_item_css > div {
  margin-bottom: 15px;
  border-radius: 5px;
  overflow: hidden;
  background-color: #fff;
  padding: 14px;
  cursor: pointer;
}
.box_item_css > div > span:nth-child(1) {
  font-size:calc(var(--zoomValue) - 2px);
  padding: 3px 10px;
  background-color: #4b71f0;
  border-radius: 6px;
  color: #fff;
  margin-right: 15px;
}
.box_item_css > div > span:nth-child(2) {
  line-height: calc(var(--zoomValue) + var(--zoomValue) / 2);
  font-size: var(--zoomValue);
  margin-top: 15px;
}
.title_css {
  text-align: center;
  font-size: 50px;
  font-weight: bold;
  padding-top: 40px;
}
/deep/ .el-dialog__header{
    padding: 30px 30px 0px;
}
/deep/ .el-dialog__title{
    font-size: 30px;
}
/deep/ .el-dialog__body{
    padding: 30px;
    font-size: 20px;
    line-height: 30px;
}
.box_item_img_css {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  /* justify-content: center; */
}
.box_item_img_css > i {
  margin: 0 15px 0 0;
  width: 180px;
  height: 0px;
}
.box_image {
  margin: 0 15px 15px 0;
  position: relative;
  width: 180px;
  height: 180px;
  border-radius: 5px;
  overflow: hidden;
}
.box_image>img{
  object-fit: cover;
  width: 180px;
  height: 180px;
  border-radius: 5px;
}
.box_file_css{
  width: 100%;
  padding: 12px 12px;
  margin-bottom: 10px;
  background-color: #758fe6;
  border-radius: 10px;
  color:#fff;
  font-size:22px;
  cursor: pointer;
}
.video_cssB {
  width: 100vw;
  height: 100vh;
  object-fit: contain;
  margin: 0;
  padding: 0;
}
.top_drawer_cssB{
  position: absolute;
  top: 30px;
  right: 30px;
  z-index: 99999;
}
</style>